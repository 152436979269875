export function useFormState<T extends Record<string, any>>(
    form: HTMLFormElement,
    onChange?: () => void,
): T {
    const state: T = {} as T;
    const elements = ([...form.elements] as HTMLInputElement[])
        .filter(e => !!e.name);
    elements.forEach(e => {
        // @ts-ignore
        state[e.name] = e.value || '';
    });
    form.addEventListener('input', (ev) => {
        // @ts-ignore
        state[ev.target.name] = ev.target.value;
        onChange && onChange();
    });
    return state;
}
