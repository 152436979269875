import {Config} from "../models/config";

export const config: Config = {
    appTitle: 'Sense Med Concept - generator podpisu',
    company: {
        name: 'Sense Med Concept',
        city: 'Warszawa',
        street: 'ul. Bobrowiecka 8',
        postalCode: '00-728',
        logoUrl: 'http://sensemedconcept.airlyaccess.pl/sense-medical-concept-logo.png',
        details:
            `VICAR MANAGEMENT Sp. z o.o. | KRS: 0000277434 | NIP: 5213441423 | REGON: 140921697
            
            Dane osobowe podane w mailu przetwarzamy w celu wymiany korespondencji drogą elektroniczną zgodnie z polityką prywatności SENSE MED CONCEPT. Administratorem Danych Osobowych jest VICAR MANAGEMENT Sp. z o.o., z siedzibą w Warszawie, tel. +48 22 180 20 00. Administrator wyznaczył Inspektora Ochrony Danych w osobie Adama Baworowskiego, e-mail: <a href="mailto:iod@sensemedconcept.com" style="color: #CCCCCC; text-decoration: none;">iod@sensemedconcept.com</a> lub na adres pocztowy.`
    }
}
