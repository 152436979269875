import {appTemplate} from "./app.template";
import {config} from "../config/config";
import {Company} from "../models/company";
import {Employee} from "../models/employee";
import {State} from "../models/state";
import styles from "../styles.scss";
import {signatureTemplate} from "./signature.template";
import {useFormState} from "../utils/use-form-state";
import * as copy from 'copy-to-clipboard';

export class SignatureGenerator extends HTMLElement {
    static tagName: string = 'signature-generator';
    private state: State;

    connectedCallback() {
        this.render();
        this.state = this.initState();
        this.initDownload();
        this.initCopy();
        this.update();
    }

    private render() {
        const style = document.createElement('style');
        style.innerText = styles.toString();
        document.title = config.appTitle;
        document.head.appendChild(style);
        this.appendChild(appTemplate(config));

    }

    private initState(): State {
        return {
            company: useFormState<Company>(
                document.getElementById('company-form') as HTMLFormElement,
                () => this.update()
            ),
            employee: useFormState<Employee>(
                document.getElementById('employee-form') as HTMLFormElement,
                () => this.update()
            )
        }
    }

    private update() {
        this.state.company.details = this.state.company.details.replace(/\n/g, "<br />");
        const preview = this.querySelector('#preview') as HTMLElement;
        preview.innerHTML = signatureTemplate(this.state);
    }

    private download() {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(signatureTemplate(this.state)));
        element.setAttribute('download', `Stopka ${this.state.employee.name}.html`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    private initDownload() {
        const button = this.querySelector('#download-button');
        button.addEventListener('click', () => {
            this.download();
        });
    }

    private initCopy() {
        const button = this.querySelector('#copy-button');
        button.addEventListener('click', () => {
            this.copyToClipboard();
        });
    }

    private copyToClipboard() {
        copy(signatureTemplate(this.state));
    }

}

customElements.define(SignatureGenerator.tagName, SignatureGenerator);
