import {State} from "../models/state";

export function signatureTemplate({employee, company}: State): string {
    return `
    <!doctype html>
    <html lang="pl">
        <head>
            <meta charset="UTF-8">
            <title>${employee.name}</title>
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Red+Hat+Display" rel="stylesheet">
            <style rel="stylesheet">
                a {
                    color: #CCCCCC;
                    text-decoration: none;
                }
            </style>
        </head>
        <body>
          <table style="margin-top: 2rem; border-collapse: collapse; border: none;">
                <tr style="border: none;">
                    <td style="padding-right: 1rem; vertical-align: top; border: none;">
                        <img 
                            style="display: block; width: 132px;" 
                            alt="${company.name}" 
                            title="${company.name}" 
                            src="${company.logoUrl || 'https://www.kadencewp.com/wp-content/uploads/2020/10/alogo-2.png'}"
                        />
                    </td>
                    <td width="430" style="max-width: 28rem; color: #888888; text-decoration: none; line-height: 1; border: none;">
                        <h2 style="font-family: 'Red Hat Display', 'Calibri', 'Helvetica', 'Times New Roman', serif; font-size: 1.4rem; font-weight: 300; margin-top: 0; margin-bottom: 0;">
                            ${employee.name}
                        </h2>
                        <div style="font-family: 'Red Hat Display', 'Google Sans', 'Roboto', Helvetica, Arial, sans-serif;">
                            <div style="font-size: 0.75rem; margin-top: 0.25rem;">${employee.jobTitle}</div>
                            <div
                                style="font-size: 0.75rem; line-height: 1.35;">
                                <section style="margin-top: 1rem;">
                                    <div>
                                        <a href="tel:${employee.phone}"
                                           style="color: #888888; text-decoration: none;">${employee.phone || 'Nr telefonu'}</a>
                                    </div>
                                    <div>
                                        <a href="mailto:${employee.email}"
                                           style="color: #888888; text-decoration: none;">${employee.email || 'Adres e-mail'}</a>
                                    </div>
                                </section>
                                <section style="margin-top: 1rem;">
                                    <div>${company.name || 'Nazwa firmy'}</div>
                                    <div>${company.street || 'Ulica i numer'}</div>
                                    <div>${company.postalCode || '00-000'} ${company.city || 'Miasto'}</div>
                                </section>
                                <section style="margin-top: 1rem; color: #CCCCCC; font-size: 0.625rem;">
                                    <div>${company.details}</div>
                                </section>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </body>
    </html>
       `.replace(/\n/g, "")
        .replace(/[\t ]+\</g, "<")
        .replace(/\>[\t ]+\</g, "><")
        .replace(/\>[\t ]+$/g, ">");
}
