import {Config} from "../models/config";

export const appTemplate = (config: Config): HTMLDivElement => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('wrapper');
    wrapper.innerHTML = `
    <form id="company-form">
        <h2>Dane firmy</h2>
        <label>
            <span>Nazwa firmy</span>
            <input type="text" name="name" value="${config.company.name}"/>
        </label>
        <label>
            <span>Ulica, nr domu i mieszkania</span>
            <input type="text" name="street" value="${config.company.street}"/>
        </label>
        <label>
            <span>Miasto</span>
            <input type="text" name="city" value="${config.company.city}"/>
        </label>
        <label>
            <span>Kod pocztowy</span>
            <input type="text" name="postalCode" value="${config.company.postalCode}"/>
        </label>
        <label>
            <span>Logo (adres URL)</span>
            <input type="text" name="logoUrl" value="${config.company.logoUrl}"/>
        </label>
        <label>
            <span>Szczegóły (HTML lub zwykły tekst)</span>
            <textarea name="details">${config.company.details}</textarea>
            <div id="editor"></div>
        </label>
    </form>
    <form id="employee-form">
        <h2>Dane pracownika</h2>
        <label>
            <span>Imię i nazwisko</span>
            <input type="text" name="name"/>
        </label>
        <label>
            <span>Stanowisko pracy</span>
            <input type="text" name="jobTitle"/>
        </label>
        <label>
            <span>Nr telefonu</span>
            <input type="text" name="phone"/>
        </label>
        <label>
            <span>E-mail</span>
            <input type="text" name="email"/>
        </label>
    </form>
    <div class="buttons">
        <button id="download-button" type="button">Zapisz na dysku</button>
        <button id="copy-button" type="button">Kopiuj do schowka</button>
    </div>
    <div id="preview"></div>
    `;
    return wrapper;
};
